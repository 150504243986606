













































































































































































































































































































































import {
  ref,
  defineComponent,
  computed,
} from '@vue/composition-api';
import router from '@/router';
import Store from '@/store';
import { getElementId, delayCall } from '@/utils';
import { IReferenceListRecord } from '@/types';
import ReferenceListDeleteItemDialog from './ReferenceListDeleteItemDialog.vue';
import ReferenceListAddLabourItemDialog from './ReferenceListAddLabourItemDialog.vue';
import ReferenceListAddMaterialItemDialog from './ReferenceListAddMaterialItemDialog.vue';
import ReferenceListAddPlantItemDialog from './ReferenceListAddPlantItemDialog.vue';
import ReferenceListAddILOSItemDialog from './ReferenceListAddILOSItemDialog.vue';

export default defineComponent({
  components: {
    ReferenceListDeleteItemDialog,
    ReferenceListAddLabourItemDialog,
    ReferenceListAddMaterialItemDialog,
    ReferenceListAddPlantItemDialog,
    ReferenceListAddILOSItemDialog,
  },
  setup() {
    const headers = {
      labour: [
        { text: 'List Name', value: 'name', align: 'start' },
        { text: 'Work Order Templates', value: 'workOrderTemplates' },
        { text: 'Employee ID', value: 'employeeId' },
        { text: 'Employee Name', value: 'employeeName' },
        { text: 'Labour Role', value: 'labourRole' },
        { text: 'Team', value: 'team' },
        { text: 'Resource Type', value: 'resourceType' },
        { text: 'Contract', value: 'contract' },
        { text: 'Payroll Number', value: 'payrollNumber' },
        {
          text: 'Actions', value: 'actions', sortable: false, width: '120px',
        },
      ],
      material: [
        { text: 'List Name', value: 'name', align: 'start' },
        { text: 'Work Order Templates', value: 'workOrderTemplates' },
        { text: 'Description', value: 'description' },
        { text: 'Material ID', value: 'id' },
        { text: 'Category', value: 'category' },
        { text: 'Sub Category', value: 'subCategory' },
        { text: 'Commercial Category', value: 'commercialCategory' },
        { text: 'Manufacturers Code', value: 'manufacturersCode' },
        { text: 'Unit', value: 'unit' },
        { text: 'Rate', value: 'rate' },
        {
          text: 'Actions', value: 'actions', sortable: false, width: '120px',
        },
      ],
      plant: [
        { text: 'List Name', value: 'name', align: 'start' },
        { text: 'Work Order Templates', value: 'workOrderTemplates' },
        { text: 'Plant ID', value: 'id' },
        { text: 'Description', value: 'description' },
        { text: 'Unit', value: 'unit' },
        { text: 'Rate', value: 'rate' },
        {
          text: 'Actions', value: 'actions', sortable: false, width: '120px',
        },
      ],
      ilos: [
        { text: 'List Name', value: 'name', align: 'start' },
        { text: 'Work Order Templates', value: 'workOrderTemplates' },
        { text: 'ILOS ID', value: 'id' },
        { text: 'Description', value: 'description' },
        {
          text: 'Actions', value: 'actions', sortable: false, width: '120px',
        },
      ],
    };

    const { listId } = router.app.$route.params;
    const { listType } = router.app.$route.params;

    const paginationChanging = ref(false);

    const listItems = computed(() => Store.getters['referenceListItems/getData'] as IReferenceListRecord[]);
    const isLoading = computed(() => Store.getters['referenceListItems/getLoading'] as boolean);
    const mainListLoading = computed(() => Store.getters['referenceList/getLoading'] as boolean);

    const filterObject = computed(() => Store.getters['referenceListItems/getFilter']);
    const pagination = computed(() => Store.getters['referenceListItems/getPagination']);

    const openedPanel = ref([]);

    /**
     * Updates filters in the ReferenceListItemsStore and loads in data
     */
    const getListItems = async () => {
      openedPanel.value = [];
      await Store.dispatch('referenceListItems/loadListItems');
    };

    const loadData = async () => {
      // prevent previously viewed list data from being shown while loading in the selected list
      await Store.dispatch('referenceListItems/resetStoreState');
      await Store.dispatch('referenceList/loadReferenceLists')
        .then(async () => {
          await Store.dispatch('referenceListItems/setListId', listId);
          getListItems();
        });
    };

    const onFilterChange = () => {
      delayCall(() => {
        Store.dispatch('referenceListItems/setPaginationPage', 1);
        getListItems();
      }, 750);
    };

    /**
     * Gets the new page and updates the data pagination.page in the ReferenceListItemsStore
     * and calls getListItems()
     * @param {number} page New page number
     */
    const onPageChange = async (page: number) => {
      paginationChanging.value = true;
      await Store.dispatch('referenceListItems/setPaginationPage', page);
      await getListItems();
      paginationChanging.value = false;
    };

    /**
     * Gets the new number of items epr page and updates the data pagination.itemsPerPage
     * in the ReferenceListItemsStore and calls getListItems()
     * @param {number} itemsPerPage Items per page
     * @return {void}
     */
    const onItemsPerPageChange = async (itemsPerPage: number) => {
      paginationChanging.value = true;
      await Store.dispatch('referenceListItems/setPaginationItemsPerPage', itemsPerPage);
      await getListItems();
      paginationChanging.value = false;
    };

    /**
     * Gets new sort by value and updates filter.sortBy in the ReferenceListItemsStore
     * and calls getListItems()
     * @param {string} sortBy Items per page
     */
    const onColSortBy = (sortBy: string) => {
      const sortByValue = sortBy || '';
      Store.dispatch('referenceListItems/setFilterSortBy', sortByValue);
      getListItems();
    };

    /**
     * Gets descendent boolean and updates filter.isDesc in the ReferenceListItemsStore
     * and calls getListItems()
     * @param {boolean} sortBy Items per page
     */
    const onColSortDesc = (isDesc: boolean) => {
      const isDescValue = isDesc ? '-' : '';
      Store.dispatch('referenceListItems/setFilterIsDesc', isDescValue);
      getListItems();
    };

    loadData();

    return {
      headers,
      listItems,
      openedPanel,
      getElementId,
      isLoading,
      mainListLoading,
      listType,
      filterObject,
      onFilterChange,
      onPageChange,
      onItemsPerPageChange,
      onColSortBy,
      onColSortDesc,
      pagination,
      paginationChanging,
    };
  },
});
