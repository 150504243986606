



























import { defineComponent } from '@vue/composition-api';
import router from '@/router';
import ReferenceListItemsTable from '@/components/ReferenceLists/ReferenceListItemsTable.vue';
import ReferenceListAddLabourItemDialog from '@/components/ReferenceLists/ReferenceListAddLabourItemDialog.vue';
import ReferenceListAddMaterialItemDialog from '@/components/ReferenceLists/ReferenceListAddMaterialItemDialog.vue';
import ReferenceListAddPlantItemDialog from '@/components/ReferenceLists/ReferenceListAddPlantItemDialog.vue';
import ReferenceListAddILOSItemDialog from '@/components/ReferenceLists/ReferenceListAddILOSItemDialog.vue';
import ReferenceListItemsBulkUpload from '@/components/ReferenceLists/ReferenceListItemsBulkUpload.vue';

export default defineComponent({
  components: {
    ReferenceListItemsTable,
    ReferenceListAddLabourItemDialog,
    ReferenceListAddMaterialItemDialog,
    ReferenceListAddPlantItemDialog,
    ReferenceListAddILOSItemDialog,
    ReferenceListItemsBulkUpload,
  },

  setup() {
    const { listType } = router.app.$route.params;

    return { listType };
  },
});
