var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reference-list-items-table"},[_c('v-data-table',{staticClass:"\n      reference-list-items-table__data-table\n      fill-height\n      d-flex\n      flex-column\n      justify-space-between\n    ",class:{ 'pointer-events-none': _vm.paginationChanging },attrs:{"headers":_vm.headers[_vm.listType],"items":_vm.listItems,"loading":_vm.isLoading || _vm.mainListLoading,"page":_vm.pagination.page,"items-per-page":_vm.pagination.itemsPerPage,"server-items-length":_vm.pagination.serverItemsLength,"fixed-header":""},on:{"update:page":[function($event){return _vm.$set(_vm.pagination, "page", $event)},_vm.onPageChange],"update:serverItemsLength":function($event){return _vm.$set(_vm.pagination, "serverItemsLength", $event)},"update:server-items-length":function($event){return _vm.$set(_vm.pagination, "serverItemsLength", $event)},"update:items-per-page":_vm.onItemsPerPageChange,"update:sort-by":_vm.onColSortBy,"update:sort-desc":_vm.onColSortDesc},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"readonly":"","value":header.text},on:{"click":function($event){$event.stopPropagation();}}})]}},{key:"header.workOrderTemplates",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"readonly":"","value":header.text},on:{"click":function($event){$event.stopPropagation();}}})]}},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(header.text))])]}},{key:"header.employeeId",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"id":"ref-list-items__employee-id-filter","data-column-filter":"employeeId","clearable":"","label":header.text},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onFilterChange()}},model:{value:(_vm.filterObject.employeeId),callback:function ($$v) {_vm.$set(_vm.filterObject, "employeeId", $$v)},expression:"filterObject.employeeId"}})]}},{key:"header.employeeName",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"id":"ref-list-items__employee-name-filter","data-column-filter":"employeeName","clearable":"","label":header.text},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onFilterChange()}},model:{value:(_vm.filterObject.employeeName),callback:function ($$v) {_vm.$set(_vm.filterObject, "employeeName", $$v)},expression:"filterObject.employeeName"}})]}},{key:"header.labourRole",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"id":"ref-list-items__labour-role-filter","data-column-filter":"labourRole","clearable":"","label":header.text},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onFilterChange()}},model:{value:(_vm.filterObject.labourRole),callback:function ($$v) {_vm.$set(_vm.filterObject, "labourRole", $$v)},expression:"filterObject.labourRole"}})]}},{key:"header.team",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"id":"ref-list-items__team-filter","data-column-filter":"team","clearable":"","label":header.text},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onFilterChange()}},model:{value:(_vm.filterObject.team),callback:function ($$v) {_vm.$set(_vm.filterObject, "team", $$v)},expression:"filterObject.team"}})]}},{key:"header.resourceType",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"id":"ref-list-items__resource-type-filter","data-column-filter":"resourceType","clearable":"","label":header.text},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onFilterChange()}},model:{value:(_vm.filterObject.resourceType),callback:function ($$v) {_vm.$set(_vm.filterObject, "resourceType", $$v)},expression:"filterObject.resourceType"}})]}},{key:"header.contract",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"id":"ref-list-items__contract-filter","data-column-filter":"contract","clearable":"","label":header.text},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onFilterChange()}},model:{value:(_vm.filterObject.contract),callback:function ($$v) {_vm.$set(_vm.filterObject, "contract", $$v)},expression:"filterObject.contract"}})]}},{key:"header.payrollNumber",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"id":"ref-list-items__payroll-number-filter","data-column-filter":"payrollNumber","clearable":"","label":header.text},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onFilterChange()}},model:{value:(_vm.filterObject.payrollNumber),callback:function ($$v) {_vm.$set(_vm.filterObject, "payrollNumber", $$v)},expression:"filterObject.payrollNumber"}})]}},{key:"header.id",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"id":"ref-list-items__item-id-filter","data-column-filter":"id","clearable":"","label":header.text},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onFilterChange()}},model:{value:(_vm.filterObject.id),callback:function ($$v) {_vm.$set(_vm.filterObject, "id", $$v)},expression:"filterObject.id"}})]}},{key:"header.category",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"id":"ref-list-items__category-filter","data-column-filter":"category","clearable":"","label":header.text},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onFilterChange()}},model:{value:(_vm.filterObject.category),callback:function ($$v) {_vm.$set(_vm.filterObject, "category", $$v)},expression:"filterObject.category"}})]}},{key:"header.subCategory",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"id":"ref-list-items__subcategory-filter","data-column-filter":"subCategory","clearable":"","label":header.text},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onFilterChange()}},model:{value:(_vm.filterObject.subCategory),callback:function ($$v) {_vm.$set(_vm.filterObject, "subCategory", $$v)},expression:"filterObject.subCategory"}})]}},{key:"header.commercialCategory",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"id":"ref-list-items__commercial-category-filter","data-column-filter":"commercialCategory","clearable":"","label":header.text},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onFilterChange()}},model:{value:(_vm.filterObject.commercialCategory),callback:function ($$v) {_vm.$set(_vm.filterObject, "commercialCategory", $$v)},expression:"filterObject.commercialCategory"}})]}},{key:"header.manufacturersCode",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"id":"ref-list-items__manufacturers-code-filter","data-column-filter":"manufacturersCode","clearable":"","label":header.text},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onFilterChange()}},model:{value:(_vm.filterObject.manufacturersCode),callback:function ($$v) {_vm.$set(_vm.filterObject, "manufacturersCode", $$v)},expression:"filterObject.manufacturersCode"}})]}},{key:"header.unit",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"id":"ref-list-items__unit-filter","data-column-filter":"unit","clearable":"","label":header.text},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onFilterChange()}},model:{value:(_vm.filterObject.unit),callback:function ($$v) {_vm.$set(_vm.filterObject, "unit", $$v)},expression:"filterObject.unit"}})]}},{key:"header.rate",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"id":"ref-list-items__rate-filter","data-column-filter":"rate","clearable":"","label":header.text},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onFilterChange()}},model:{value:(_vm.filterObject.rate),callback:function ($$v) {_vm.$set(_vm.filterObject, "rate", $$v)},expression:"filterObject.rate"}})]}},{key:"header.description",fn:function(ref){
var header = ref.header;
return [_c('v-text-field',{staticClass:"d-inline-block font-weight-regular",attrs:{"id":"ref-list-items__description-filter","data-column-filter":"description","clearable":"","label":header.text},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.onFilterChange()}},model:{value:(_vm.filterObject.description),callback:function ($$v) {_vm.$set(_vm.filterObject, "description", $$v)},expression:"filterObject.description"}})]}},{key:"item.workOrderTemplates",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"templates-dropdown",attrs:{"items":item.workOrderTemplates,"value":item.workOrderTemplates[0],"item-text":"identifier","item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":true}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"regular-text",attrs:{"id":attrs['aria-labelledby']},domProps:{"textContent":_vm._s(item.identifier)}})],1)],1)]}}],null,true)})]}},{key:"item.labourRole",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.labourRole ? item.labourRole.value : ''))])]}},{key:"item.resourceType",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.resourceType ? item.resourceType.value : ''))])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.category ? item.category.value : ''))])]}},{key:"item.commercialCategory",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.commercialCategory ? item.commercialCategory.value : ''))])]}},{key:"item.subCategory",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.subCategory ? item.subCategory.value : ''))])]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.unit ? item.unit.value : ''))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('ReferenceListDeleteItemDialog',{attrs:{"item-id":item.id}}),(_vm.listType === 'labour')?_c('ReferenceListAddLabourItemDialog',{attrs:{"action":"edit","item-data":item}}):_vm._e(),(_vm.listType === 'material')?_c('ReferenceListAddMaterialItemDialog',{attrs:{"action":"edit","item-data":item}}):_vm._e(),(_vm.listType === 'plant')?_c('ReferenceListAddPlantItemDialog',{attrs:{"action":"edit","item-data":item}}):_vm._e(),(_vm.listType === 'ilos')?_c('ReferenceListAddILOSItemDialog',{attrs:{"action":"edit","item-data":item}}):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }