var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([(_vm.action === 'add')?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"id":"ref-list-ilos-item__create-btn","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" New ")])]}}:{key:"activator",fn:function(ref){
var ondialog = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var ontooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"ref-list-ilos-item__edit-btn","icon":""},on:{"click":_vm.setItemData}},'v-btn',attrs,false),Object.assign({}, ontooltip, ondialog)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form"},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.dialogTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"id":"ref-list-ilos-item__description-field","label":"Description *","rules":[_vm.rules.required, _vm.rules.maxChars],"counter":"100"},model:{value:(_vm.newListItem.description),callback:function ($$v) {_vm.$set(_vm.newListItem, "description", $$v)},expression:"newListItem.description"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"id":"ref-list-ilos-item__cancel-btn","color":"secondary","text":"","disabled":_vm.isLoading},on:{"click":_vm.closeDialog}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"id":"ref-list-ilos-item__save-btn","color":"primary","text":"","disabled":!_vm.validateListData || _vm.isLoading,"loading":_vm.isLoading},on:{"click":_vm.saveChanges}},[_vm._v(" "+_vm._s(_vm.action === 'add' ? 'Add' : 'Save')+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }