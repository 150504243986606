















































































































































import {
  defineComponent, reactive, ref, computed, PropType,
} from '@vue/composition-api';
import Store from '@/store';
import { IEmployee, IReferenceListLabourItem, ITableLookupsRecord } from '@/types';

export default defineComponent({
  props: {
    action: {
      type: String,
      required: true,
    },
    itemData: {
      type: Object as PropType<IReferenceListLabourItem>,
      default: () => {},
    },
  },
  setup(props) {
    const dialog = ref(false);
    const form = ref();
    const isLoading = computed(() => Store.getters['referenceListItems/getLoading'] as boolean);

    const defaultData = {
      employeeName: '',
      employeeId: null,
      team: '',
      labourRoleLookupId: null,
      contract: '',
      resourceTypeLookupId: null,
      payrollNumber: '',
    } as IReferenceListLabourItem;

    const dialogTitle = computed(() => {
      if (props.action === 'add') return 'Add a new item';
      return 'Edit item';
    });

    const newListItem = reactive(defaultData);

    const employees = computed(() => Store.getters['referenceListItems/getEmployees'] as IEmployee[]);
    const labourRoles = computed(() => Store.getters['tableLookups/getLabourRoles'] as ITableLookupsRecord[]);
    const resourceTypes = computed(() => Store.getters['tableLookups/getResourceTypes'] as ITableLookupsRecord[]);

    const lookupLoading = computed(() => Store.getters['tableLookups/getLoading'] as boolean);

    const rules = {
      required: (value: string): boolean | string => !!value || 'This field is required',
      maxChars: (value: string): boolean | string => {
        if (!value) return true;
        return value.length <= 100 || 'Max 100 characters';
      },
    };

    /**
     * @description When the user opens up the dialog using the 'edit' button, we set newListItem to the
     * existing item's data. We also need to extract the lookup id properties from the labourRole and resourceType
     * objects.
     *
     * @author Ewa Murjas
     */
    const setItemData = () => {
      Object.assign(newListItem, props.itemData, {
        labourRoleLookupId: props.itemData.labourRole?.id,
        resourceTypeLookupId: props.itemData.resourceType?.id,
      });
    };

    const validateListData = computed((): boolean => {
      if (!newListItem.employeeName) return false;
      const maxChars = rules.maxChars(newListItem.team) && rules.maxChars(newListItem.contract) && rules.maxChars(newListItem.payrollNumber);
      return (
        typeof maxChars === 'boolean'
          && maxChars
      );
    });

    const onEmployeeSelected = (employeeData: IEmployee) => {
      if (employeeData) {
        newListItem.employeeName = employeeData.fullName;
        newListItem.employeeId = employeeData.id;
      } else {
        newListItem.employeeName = '';
        newListItem.employeeId = null;
      }
    };

    const closeDialog = () => {
      form.value.reset();
      Object.assign(newListItem, defaultData);
      dialog.value = false;
    };

    const saveChanges = async () => {
      const storeAction = props.action === 'add' ? 'createNewListItem' : 'updateListItem';
      await Store.dispatch(`referenceListItems/${storeAction}`, newListItem);
      closeDialog();
    };

    /**
     * Load dropdown options for the dialog
     */
    const loadTableLookups = () => {
      Store.dispatch('tableLookups/loadTableLookupsByType', 'labour_role');
      Store.dispatch('tableLookups/loadTableLookupsByType', 'resource_type');
      Store.dispatch('referenceListItems/loadEmployees');
    };

    loadTableLookups();

    return {
      newListItem,
      employees,
      rules,
      dialog,
      form,
      closeDialog,
      saveChanges,
      validateListData,
      isLoading,
      lookupLoading,
      labourRoles,
      resourceTypes,
      setItemData,
      dialogTitle,
      onEmployeeSelected,
    };
  },
});
