























































































































































import {
  defineComponent, reactive, ref, computed, PropType,
} from '@vue/composition-api';
import _ from 'lodash';
import Store from '@/store';
import { IReferenceListMaterialItem, ITableLookupsRecord } from '@/types';

export default defineComponent({
  props: {
    action: {
      type: String,
      required: true,
    },
    itemData: {
      type: Object as PropType<IReferenceListMaterialItem>,
      default: () => {},
    },
  },
  setup(props) {
    const dialog = ref(false);
    const form = ref();
    const isLoading = computed(() => Store.getters['referenceListItems/getLoading'] as boolean);

    const defaultData = {
      description: '',
      categoryLookupId: null,
      subCategoryLookupId: null,
      commercialCategoryLookupId: null,
      manufacturersCode: '',
      unitLookupId: null,
      rate: null,
    };

    const dialogTitle = computed(() => {
      if (props.action === 'add') return 'Add a new item';
      return 'Edit item';
    });

    const newListItem = reactive(defaultData);
    const lookupLoading = computed(() => Store.getters['tableLookups/getLoading'] as boolean);

    const categories = computed(() => Store.getters['tableLookups/getCategories'] as ITableLookupsRecord[]);
    const subCategories = computed(() => Store.getters['tableLookups/getSubCategories'] as ITableLookupsRecord[]);
    const commercialCategories = computed(() => Store.getters['tableLookups/getCommercialCategories'] as ITableLookupsRecord[]);
    const units = computed(() => Store.getters['tableLookups/getUnitMaterials'] as ITableLookupsRecord[]);

    const setItemData = () => {
      const editedItemData = _.cloneDeep(props.itemData);
      Object.assign(newListItem, editedItemData, {
        categoryLookupId: props.itemData.category?.id || '',
        subCategoryLookupId: props.itemData.subCategory?.id || '',
        commercialCategoryLookupId: props.itemData.commercialCategory?.id || '',
        unitLookupId: props.itemData.unit?.id || '',
      });
    };

    const rules = {
      required: (value: string): boolean | string => !!value || 'This field is required',
      maxChars: (value: string): boolean | string => {
        if (!value) return true;
        return value.length <= 100 || 'Max 100 characters';
      },
    };

    const validateListData = computed((): boolean => {
      if (!newListItem.description) return false;
      const maxChars = rules.maxChars(newListItem.description) && rules.maxChars(newListItem.manufacturersCode);
      return (
        typeof maxChars === 'boolean'
          && maxChars
      );
    });

    /**
     * @summary Validation function for number fields, prevents the user from entering any characters
     * other than numbers and one decimal point '.'
     * @author Ewa Murjas
     */
    const isNumber = (event: KeyboardEvent, value: string) => {
      if (!/\d/.test(event.key) && (event.key !== '.' || /\./.test(value))) {
        return event.preventDefault();
      }
      return true;
    };

    const closeDialog = () => {
      form.value.reset();
      Object.assign(newListItem, defaultData);
      dialog.value = false;
    };

    const saveChanges = async () => {
      const storeAction = props.action === 'add' ? 'createNewListItem' : 'updateListItem';
      await Store.dispatch(`referenceListItems/${storeAction}`, newListItem);
      closeDialog();
    };

    /**
     * Load dropdown options for the dialog
     */
    const loadTableLookups = () => {
      Store.dispatch('tableLookups/loadTableLookupsByType', 'category');
      Store.dispatch('tableLookups/loadTableLookupsByType', 'sub_category');
      Store.dispatch('tableLookups/loadTableLookupsByType', 'commercial_category');
      Store.dispatch('tableLookups/loadTableLookupsByType', 'unit_materials');
    };

    loadTableLookups();

    return {
      newListItem,
      rules,
      dialog,
      form,
      closeDialog,
      saveChanges,
      validateListData,
      isLoading,
      lookupLoading,
      categories,
      subCategories,
      commercialCategories,
      units,
      setItemData,
      dialogTitle,
      isNumber,
    };
  },
});
