

























































































import {
  defineComponent, reactive, ref, computed, PropType,
} from '@vue/composition-api';
import _ from 'lodash';
import Store from '@/store';
import { IReferenceListMaterialItem } from '@/types';

export default defineComponent({
  props: {
    action: {
      type: String,
      required: true,
    },
    itemData: {
      type: Object as PropType<IReferenceListMaterialItem>,
      default: () => {},
    },
  },
  setup(props) {
    const dialog = ref(false);
    const form = ref();
    const isLoading = computed(() => Store.getters['referenceListItems/getLoading'] as boolean);

    const defaultData = {
      description: '',
    };

    const dialogTitle = computed(() => {
      if (props.action === 'add') return 'Add a new item';
      return 'Edit item';
    });

    const newListItem = reactive(defaultData);

    const setItemData = () => {
      const editedItemData = _.cloneDeep(props.itemData);
      delete editedItemData.unit;
      Object.assign(newListItem, editedItemData, {
        unitLookupId: props.itemData.unit?.id || '',
      });
    };

    const rules = {
      required: (value: string): boolean | string => !!value || 'This field is required',
      maxChars: (value: string): boolean | string => {
        if (!value) return true;
        return value.length <= 100 || 'Max 100 characters';
      },
    };

    const validateListData = computed((): boolean => {
      if (!newListItem.description) return false;
      const maxChars = rules.maxChars(newListItem.description);
      return (
        typeof maxChars === 'boolean'
          && maxChars
      );
    });

    const closeDialog = () => {
      form.value.reset();
      Object.assign(newListItem, defaultData);
      dialog.value = false;
    };

    const saveChanges = async () => {
      const storeAction = props.action === 'add' ? 'createNewListItem' : 'updateListItem';
      await Store.dispatch(`referenceListItems/${storeAction}`, newListItem);
      closeDialog();
    };

    return {
      newListItem,
      rules,
      dialog,
      form,
      closeDialog,
      saveChanges,
      validateListData,
      isLoading,
      setItemData,
      dialogTitle,
    };
  },
});
