






































































































































import {
  computed, defineComponent, Ref, ref,
} from '@vue/composition-api';
import router from '@/router';
import Store from '@/store';
import { IFile, IReferenceListRecord } from '@/types';

export default defineComponent({

  setup() {
    const dialog = ref(false);
    const isValid = ref(true);
    const errors: Ref<string[]> = ref([]);
    const uploadFile = ref();

    const { listId } = router.app.$route.params;

    const isLoading = computed(
      () => Store.getters['referenceListItems/getLoading'] as boolean,
    );
    const listName = computed(() => {
      const listItems = Store.getters['referenceList/getData'];
      const foundList = listItems.find((item: IReferenceListRecord) => item.id === listId);
      return foundList?.name || '';
    });

    const rules = {
      required: (value: string): boolean | string => !!value || 'This field is required',
      noSpecialCharacters: (file: IFile) => {
        if (!file) return true;
        const regex = new RegExp(/^[a-zA-Z0-9\s_.-]+$/);
        return (
          regex.test(file.name)
          || 'Only alpha numeric characters, hyphens, underscores and spaces allowed'
        );
      },
      lengthRule: (file: IFile) => {
        if (!file) return true;
        return (
          (file.name.length >= 3 && file.name.length <= 100)
          || 'File name must be between 3 and 100 characters'
        );
      },
      onlyAllowedFormats: (file: IFile) => {
        if (!file) return true;
        const allowedFileTypes = [
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ];
        const isAllowed = allowedFileTypes.some((fileType) => file.type.includes(fileType));
        return isAllowed || 'Invalid file type';
      },
      maxSize: (file: IFile) => {
        if (!file) return true;
        return file.size <= 10000000 || 'Max file size is 10mb';
      },
    };

    /**
     * Check that the file input passes validation.
     * This determines whether the upload button is enabled or disabled.
     */
    const validateFileInput = computed((): boolean => {
      const required = rules.required(uploadFile.value);
      const specialCharacters = rules.noSpecialCharacters(uploadFile.value);
      const length = rules.lengthRule(uploadFile.value);
      const format = rules.onlyAllowedFormats(uploadFile.value);
      const fileSize = rules.maxSize(uploadFile.value);

      return (
        typeof required === 'boolean'
        && required
        && typeof specialCharacters === 'boolean'
        && specialCharacters
        && typeof length === 'boolean'
        && length
        && typeof format === 'boolean'
        && format
        && typeof fileSize === 'boolean'
        && fileSize
      );
    });

    const closeDialog = () => {
      uploadFile.value = null;
      dialog.value = false;
    };

    const uploadRefListItems = async () => {
      await Store.dispatch('referenceListItems/bulkUploadItems', uploadFile.value)
        .then(() => {
          closeDialog();
        })
        .catch((error) => {
          if (error.response?.data.validationErrors) {
            isValid.value = false;
            Object.keys(error.response.data.validationErrors).forEach((key) => {
              errors.value.push(error.response.data.validationErrors[key]);
            });
          } else {
            closeDialog();
          }
        });
    };

    const downloadTemplate = () => {
      Store.dispatch('referenceListItems/loadBulkUploadTemplate');
    };

    const showUploadScreen = () => {
      uploadFile.value = null;
      isValid.value = true;
      errors.value = [];
    };

    return {
      closeDialog,
      dialog,
      rules,
      uploadFile,
      validateFileInput,
      isLoading,
      downloadTemplate,
      uploadRefListItems,
      listName,
      isValid,
      showUploadScreen,
      errors,
    };
  },
});
