


























































import {
  defineComponent, ref, computed,
} from '@vue/composition-api';
import Store from '@/store';

export default defineComponent({
  props: {
    itemId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const dialog = ref(false);
    const isLoading = computed(() => Store.getters['referenceListItems/getLoading']);

    const closeDialog = () => {
      dialog.value = false;
    };

    const deleteListItem = async () => {
      await Store.dispatch('referenceListItems/deleteListItem', props.itemId);
      closeDialog();
    };

    return {
      dialog,
      closeDialog,
      deleteListItem,
      isLoading,
    };
  },
});
